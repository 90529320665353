<template>
	<div>
        <div class="redes-flotantes">
            <div class="separator" style="clear: both; text-align: left;">
                <a id="redes-flotantes-link" @click.prevent="show" title="Contactar vía Whatsapp">
                    <img src="../assets/whatsapp.png">
                </a>
            </div>
        </div>

		<modal 
            id="modal-form-whatsapp"
            name="modal-form-whatsapp" 
            height="auto"
            :class="isFormPendingClass"
        >   
            <template v-if="type === 'bootstrap-2'">
                <ModalWhatsappBootstrap2Component />
            </template>
            <template v-else-if="type === 'bootstrap-3'">
                <ModalWhatsappBootstrap3Component />
            </template>
			<template v-else-if="type === 'bootstrap-4'">
                <ModalWhatsappBootstrap4Component />
            </template>
		</modal>
	</div>
</template>

<script>
import axios from 'axios'
/** Components */
import ModalWhatsappBootstrap2Component from './modals/ModalWhatsappBootstrap2Component'
import ModalWhatsappBootstrap3Component from './modals/ModalWhatsappBootstrap3Component'
import ModalWhatsappBootstrap4Component from './modals/ModalWhatsappBootstrap4Component'

export default {
    name: 'whatsapp-dialog',
	props: {
		type: {
            type: String,
            required: true,
            default: 'bootstrap-4'
        },
        endpoint: {
            type: String,
            required: true,
            default: ''
        },
        proyect: {
            type: String,
            required: true,
            default: ''
        },
        phone: {
            type: String,
            required: true,
            default: ''
        }
	},
    components: {
        ModalWhatsappBootstrap2Component,
        ModalWhatsappBootstrap3Component,
        ModalWhatsappBootstrap4Component
    },
    data() {
        return {
            isFormPending: false
        }
    },
    methods: {
        async sendContactWhatsappForm(data) {
            try {
                const response = await axios.post(this.endpoint, data)
                return response.data
            } catch (error) {
                console.log(error)
            }
        },
		show () {
			this.$modal.show('modal-form-whatsapp');            
        },
        hide () {
			this.$modal.hide('modal-form-whatsapp');
        },        
	},
    created() {
        this.$bus.on('eventHideWhatsappModal', () => {
            this.hide()
        });

        this.$bus.on('eventWhatsappModalFormSubmit', (modalFormWhatssap) => {
            const data = {
                name: modalFormWhatssap.name,
                lastname: modalFormWhatssap.lastname,
                email: modalFormWhatssap.email,
                phone: modalFormWhatssap.country + modalFormWhatssap.phone,
                message: modalFormWhatssap.message,
                proyect: this.proyect
            }

            this.isFormPending = true

            this.sendContactWhatsappForm(data).then(() => {
                this.isFormPending = false
                
                const url = `https://wa.me/${this.phone}?text=${modalFormWhatssap.message}`
                //Open a new tab
                window.open(url, '_blank');

                //Hide Modal
                this.hide()
            })
        });
    },
    computed: {
        isFormPendingClass() {
            return this.isFormPending ? 'form-pending-class' : ''
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    .redes-flotantes {
        position: fixed;
        left: 20px;
        bottom: 30px;
        z-index: 200;
    }

    .redes-flotantes img {
        width: 70px;
        float: left;
        clear: left;
        -moz-transform: scale(1);
        -webkit-transform: scale(1);
        -o-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
        -webkit-transition: all .2s ease-in-out;
        -moz-transition: all .2s ease-in-out;
        -o-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
    }

    .redes-flotantes img:hover {
        width: 80px;
        cursor: pointer;
    }

    .vm--modal {
        position: absolute !important;    
        top: auto !important;
        left: .50rem !important;
        bottom: 0 !important;
        max-width: 300px;
        border-radius: 0 !important;
    }

    .form-pending-class .vm--modal .modal-content {
        opacity: 0.5;
    }
</style>