<template>
    <div class="modal-content">
        <form class="mb-0" @submit.prevent="validationForm">
            <div class="modal-header">                
                <img src="../../assets/whatsapp-panel.png" alt="Whatsapp">                
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="hide">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body pb-0">
                <div class="row-alt">
                    <div class="col col-12">
                        <label for="modal-whatsapp-name">Tu Nombre</label>
                        <input 
                            type="text" 
                            name="modal-whatsapp-name" 
                            class="form-control form-control-sm required" 
                            id="modal-whatsapp-name" 
                            required="required" 
                            v-model="modalFormWhatssap.name"
                        />
                    </div>
                </div>
                <div class="row-alt">
                    <div class="col col-12">
                        <label for="modal-whatsapp-lastname">Tu Apellido</label>
                        <input 
                            type="text" 
                            name="modal-whatsapp-lastname" 
                            class="form-control form-control-sm required" 
                            id="modal-whatsapp-lastname" 
                            required="required" 
                            v-model="modalFormWhatssap.lastname"
                        />  
                    </div>
                </div>
                <div class="row-alt">
                    <div class="col col-12">
                        <label for="modal-whatsapp-email">Tu Email</label>
                        <input 
                            type="email" 
                            name="modal-whatsapp-email" 
                            class="form-control form-control-sm required" 
                            id="modal-whatsapp-email" 
                            required="required" 
                            v-model="modalFormWhatssap.email"
                        />
                    </div>
                </div>
                <div class="row-alt">
                    <div class="col col-6">
                        <label for="modal-whatsapp-prefix-phone">Teléfono</label>
                        <select 
                            name="modal-whatsapp-prefix-phone" 
                            class="form-control form-control-sm required" 
                            id="modal-whatsapp-prefix-phone" 
                            required="required"
                            v-model="modalFormWhatssap.country"
                        >
                            <option v-for="(prexfixPhone, index) in prefixPhones" :value="prexfixPhone.code" v-bind:key="index">{{ `${prexfixPhone.prefix} (${prexfixPhone.code})` }}</option>
                        </select>
                    </div>
                    <div class="col col-6 col-minus-left">
                        <label for="modal-whatsapp-phone">&nbsp;</label>
                        <input 
                            type="tel" 
                            name="modal-whatsapp-phone" 
                            class="form-control form-control-sm required" 
                            id="modal-whatsapp-phone" 
                            required="required" 
                            v-model="modalFormWhatssap.phone"
                            style="width:100%;" 
                        />
                    </div>
                </div>
                <div class="row-alt">
                    <div class="col col-12">
                        <label for="modal-whatsapp-message">Escríbenos un mensaje</label>
                        <textarea 
                            name="modal-whatsapp-message" 
                            id="modal-whatsapp-message" 
                            class="form-control form-control-sm required" 
                            rows="2" 
                            required="required"
                            v-model="modalFormWhatssap.message"
                        ></textarea>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" name="modal-whatsapp-submit" class="btn btn-primary btn-block btn-ws-submit" :disabled="isBtnSubmitDisabled">Iniciar Chat</button>
            </div>
        </form>
    </div>
</template>

<script>
import prefixPhones from './../../utils/prefixPhones'

export default {
    data() {
        return {
            modalFormWhatssap: {
                name: '',
                lastname: '',
                email: '',
                country: '+56',
                phone: '',
                message: ''
            },

            isBtnSubmitDisabled: false,
            prefixPhones: prefixPhones
        }
    },
    methods: {
        init() {
            this.modalFormWhatssap = {
                name: '',
                lastname: '',
                email: '',
                country: '+56',
                phone: '',
                message: ''
            }
        },
        hide () {
			this.$bus.emit('eventHideWhatsappModal');
		},
        validationForm() {
            let flag = true

            for (let key in this.modalFormWhatssap) {
                if (this.modalFormWhatssap[key] === '') {
                    flag = false
                }
            }

            if(flag) {
                this.isBtnSubmitDisabled = true;

                this.$bus.emit('eventWhatsappModalFormSubmit', this.modalFormWhatssap);
                
                this.isBtnSubmitDisabled = false;
                this.init()
            }
        },
    }
}
</script>

<style scoped>
    .row-alt {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        /*margin-right: -15px;
        margin-left: -15px;*/
    }

    .col {
        position: relative;
        float: left;
        max-width: 100%;
    }

    .col-3 {
        width: 25%;
    }

    .col-4 {
        width: 33%;
    }

    .col-6 {
        width: 50%;
        float: left;
    }

    .col-8 {
        width: 66.666666%;
    }

    .col-12 {
        width: 100%;
    }

    .col-minus-left {
        margin-left: -15px;
    }

    .col-minus-right {
        margin-right: -15px;
    }

    .mb-0 {
        margin-bottom: 0 !important;
    }
    
    .pb-0 {
        padding-bottom: 0 !important;
    }

    .pl-0 {
        padding-left: 0 !important;
    }

    .pr-0 {
        padding-right: 0 !important;
    }

    .clearfix {
        overflow: auto;
        zoom: 1;
        clear: both;
    }

    .modal-content {
        border: none;    
    }

    .modal-content .modal-header {
        background-color: #25D366;
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);
        border-top-left-radius: none;
        border-top-right-radius: none;
    }

    .modal-content .modal-header .close {
        font-size: 2em;
        color: #fff;
        opacity: 1;
    }

    .modal-content .modal-body {
        max-height: 600px;
    }

    .modal-content .modal-body .form-control {
        width: calc(100% - 15px);
    }

    .modal-content .modal-body .form-control:focus {
        border-color: #00c9a3;
        box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
    }

    .modal-content .modal-footer {
        border-top: none;
        background-color: transparent;
    }

    .modal-content .modal-footer .btn-ws-submit {
        background-color: #25D366 !important;
        background-image: none !important;
        color: #fff;
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);        
    }

    .modal-content .modal-footer .btn-ws-submit:hover {
        background-color: #1a9046;
    }
</style>